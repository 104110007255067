import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/index'
  },
  {
    //首页
    path: "/index",
    name: "index",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/index.vue"),
    meta: {
      title: "公务微考察"
    }
  },
  {
    //行程安排
    path: "/Home",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Itinerary/Home.vue"),
    children: [
      {
        //行程安排
        path: "/Schedule",
        name: "Schedule",
        meta: {
          title: "行程安排"
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/Itinerary/Schedule.vue"),
      },
    ],

  },
  {
    //住行安排
    path: "/Live",
    name: "Live",
    meta: {
      title: "住宿安排"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Itinerary/Live.vue"),
  },
  {
    //用餐安排
    path: "/scheme",
    name: "scheme",
    meta: {
      title: "用餐安排"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Itinerary/scheme.vue"),
  },
  {
    // 地区详情
    path: "/areaBrief",
    name: "areaBrief",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Brief/areaBrief.vue"),
  },
  
  {
    //参会人员
    path: "/Attendees",
    name: "Attendees",
    meta: {
      title: "注意事项"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Itinerary/Attendees.vue"),
  },
  {
    // 考察详情
    path: "/BasicInformation",
    name: "BasicInformation",
    meta: {
      title: "考察详情"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Brief/BasicInformation.vue"),
  },
  {
    // tab切换组件
    path: "/tabsceshi",
    name: "tabsceshi",
    meta: {
      title: "切换tabs组件"
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Itinerary/tabsceshi.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: 'hash',
  base: '/vue/app/'
});
export default router;
