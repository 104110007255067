<template>
    <div>
        <div style="display: flex;justify-content: center;">
            <img src="../assets/nonecontent.png" alt="" style="width: 300px;height: 300px; ">
        </div>
        <div style="justify-content: center;display: flex; color: #666;font-size: 16px;">暂无内容</div>
    </div>
</template>

<script>
export default {
  name: 'NoConten',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() { },
  methods: {},
};
</script>

<style scoped>

</style>
