<template>
  <div id="app">
    <!-- <Transition :name="transitionName"> -->
      <router-view class="transitionBody" />
    <!-- </Transition> -->
  </div>
</template>
<script>
  // import 'animate.css'
  export default {
    name: "",
    components: {},
    props: {},
    data() {
      return {
        transitionName: 'transitionLeft',
      };
    },
    mounted() {

    },
    computed: {

    },
    watch: {
      '$route'(to, from) {
        const arr = ['/Schedule', '/Attendees', '/Live', '/scheme', '/areaBrief', '/BasicInformation', '/index']
        this.transitionName = arr.indexOf(to.path) > arr.indexOf(from.path) ? 'transitionLeft' : 'transitionRight'
        let title = this.$route.meta.title
        document.title = title
      }

    },
    created() {
      // 禁止缩放
      // document.addEventListener('mousewheel', function (e) {
      //   e = e || window.event;
      //   if ((e.wheelDelta && event.ctrlKey) || e.detail) {
      //     event.preventDefault();
      //   }
      // }, {
      //   capture: false,
      //   passive: false,

      // });
    
    },
    methods: {
      // 标题更改
      titleH() {

      },
    }
  };
</script>
<style lang="less">
  .transitionBody {
    transition: all 0.5s ease-out;
    /* opacity: 1; */
  }

  .transitionLeft-enter,
  .transitionRight-leave-active {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    /* opacity: 0.2; */
  }

  .transitionLeft-leave-active,
  .transitionRight-enter {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    /* opacity: 0.2; */
  }

  .transitionLeft-enter-active,
  .transitionRight-enter-active {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
</style>