import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
import moment from 'moment';
import VideoPlayer from 'vue-video-player'
import noconten from './components/nofuond404.vue'
import "core-js/stable";
import "regenerator-runtime/runtime";
import "@/views/content/assets/rem"
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
// Vue.use(VueCoreVideoPlayer, {
//   lang: 'zh-CN'
// })
Vue.use(noconten);

Vue.use(Vant);
Vue.config.productionTip = false;
Vue.filter('dateFormat', (str, partten = "M月D日") => {
  return moment(str).format(partten);
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
